<template>
	<div class="content_">
		<div class="detaildiv">
			<draggable v-model="imagesList" class="image-list" @change="dragChange">
				<div v-for="(image, imgindex) in imagesList" :key="imgindex" class="image-wrap">
					<img :src="image" class="imgStyle" />
					<div class="icon-wrap" @click="removeimglist(imgindex)"><i class="el-icon-delete" /></div>
				</div>
				<el-upload class="avatar-uploaderde" action="" :http-request="handlelistSuccess" :show-file-list="false" :before-upload="beforelistUpload" multiple>
					<i class="el-icon-plus avatar-uploader-iconde" />
				</el-upload>
			</draggable>
			<div slot="tip" class="el-upload__tip">{{tips}}</div>
		</div>
	</div>
</template>
<script>
import draggable from 'vuedraggable';
export default {
	components: { draggable },
	props: {
		list: {
			type: Array,
			default() {
				return [];
			}
		},
		tips: {
			type: String,
			default() {
				return "建议最多上传3张，只能上传jpg/png文件，且不超过2m";
			}
		},
	},
	data() {
		return {
			imagesList: []
		};
	},
	watch: {
		list: {
			immediate: true, // immediate选项可以开启首次赋值监听
			handler(newVal, oldVal) {
				// console.log(newVal, oldVal)
				this.imagesList = newVal;
			}
		}
	},
	mounted: function() {},
	methods: {
		dragChange(en) {
			console.log(en);
			// console.log(this.imgData)
		},
		// 删除多图轮播
		removeimglist(imgindex) {
			this.$emit('removeimglist', imgindex);
			// this.imagesList.splice(imgindex, 1)
		},
		async handlelistSuccess({ file }) {
			var res = await this.$api.uploadHttp(file, {});
			if (res && res.status == 200) {
				this.$emit('addimglist', res.requestUrls[0]);
				this.$message.success('上传图片成功!');
			} else {
				this.$message.error('上传图失败!');
			}
		},
		beforelistUpload(file) {
			console.log(file);
			const isLt2M = file.size / 1024 / 1024 < 10;
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 10MB!');
				return false;
			}
			if (file.type == 'image/jpeg' || file.type == 'image/png') {
				console.log(this.BASEURL);
			} else {
				this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.content_ {
	background-color: #f8f8f8;
	padding: 10px;
	margin-bottom: 10px;
}
.image-list {
	display: flex;
	flex-wrap: wrap;
}
.image-list .image-wrap {
	position: relative;
	display: inline-block;
	box-sizing: content-box;
	margin: 0 8px;
	line-height: 0;
	vertical-align: top;
}
.image-list .imgStyle {
	width: 128px;
	height: 128px;
	border: 1px solid #d9d9d9;
}
.avatar-uploaderde {
	width: 128px;
	height: 128px;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
}
.avatar-uploader-iconde {
	font-size: 28px;
	color: #8c939d;
	width: 128px;
	height: 128px;
	line-height: 128px !important;
	text-align: center;
}
.image-list .icon-wrap {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 30px;
	cursor: default;
	text-align: center;
	color: #fff;
	opacity: 0;
	font-size: 20px;
	background-color: rgba(0, 0, 0, 0.7);
	transition: opacity 0.3s;
	z-index: 8888;
}
.image-list .image-wrap:hover {
	.icon-wrap {
		opacity: 1;
	}
}
.image-list .el-icon-zoom-in {
	cursor: pointer;
	margin-right: 8px;
}
.image-list .el-icon-delete {
	cursor: pointer;
}
</style>
